<template>
  <v-row justify="center">
    <v-card flat class="transparent mt-4" max-width="470" v-if="mapIsReady">
      <SimpleGoogleAutocomplete />
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'GeoscapeAutocomplete',

  props: ['value'],

  data: () => ({
    mapIsReady: false,
    address: '',
    variants: [],
    loading: false,
    search: null,
    google: '',
    formula: '',
    color: 'transparent'
  }),

  computed: {
    addresses () {
      return this.variants.map(item => item.address)
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.getVariants(val)
    }
  },
  methods: {
    notSubmited () {
      this.color = '#9004'
    },
    submit () {
      this.color = 'transparent'
      this.$emit('update:value', this.address)
    },
    catchGoogleAutocompleteEvent (event) {
      this.$emit('update:value', event.detail.address)
    }
  },

  beforeDestroy () {
    window.removeEventListener('address-selected', this.catchGoogleAutocompleteEvent)
  },

  mounted () {
    function waitForGoogleMaps () {
      if (!window.google) window.requestAnimationFrame(waitForGoogleMaps.bind(this))
      else this.mapIsReady = true
    }

    window.requestAnimationFrame(waitForGoogleMaps.bind(this))

    window.addEventListener('address-selected', this.catchGoogleAutocompleteEvent)
  }
}
</script>
